import { Box, GridItem, HStack, Text } from '@chakra-ui/react';
import { Grid8 } from './Grid';
import * as React from 'react';
import InfoDateRange from './InfoDateRange';
import Modules, { ModulesItem } from './modules';

export const Project: React.FC<{
	color?: string;
	description: string;
	endDate: string;
	modules: ModulesItem[];
	startDate: string;
	tagline: string;
	title: string;
}> = (props) => {
	return (
		<Box as="article">
			<Grid8>
				<GridItem gridColumn={{ base: '1/-1', sm: '3/-1', lg: '3/span 3' }}>
					<HStack spacing={4} alignItems="flex-start" justifyContent="space-evenly">
						<Text as="h1" wordBreak="keep-all" flex="1">
							{props.title}
						</Text>
					</HStack>
					<Text>
						<InfoDateRange startDate={props.startDate} endDate={props.endDate} />
					</Text>
					{/* <ui.Text opacity="0.5">{props.tagline}</ui.Text> */}
				</GridItem>

				<GridItem gridColumn={{ base: '1/-1', sm: '3/-1', lg: '6/-1' }}>
					<Text whiteSpace="pre-wrap">{props.description}</Text>
				</GridItem>
			</Grid8>

			<Grid8 isLarge>
				<Modules modules={props.modules} />
			</Grid8>
		</Box>
	);
};

export default Project;
