import { InferGetServerSidePropsType } from 'next';
import { PageProjectIndexProps } from '../[slug].js';
import { urlFor } from '@lib/sanity';
import { Project as TypeProject, Research as TypeResearch } from '@lib/types.js';
import * as React from 'react';
import Head from '@components/Head';
import Layout from '@layout';
import Project from '@components/Project';
import Related from '@components/Related';

/**
 * @author Matt Kenefick <matt.kenefick@buck.co>
 * @package Pages/Work
 * @project BUCK.co
 */
export default function WorkProjectIndex(props: PageProjectIndexProps) {
	if (!props.project) {
		return null;
	}

	const projectImage: string = urlFor(props.project?.imageCover).width(400).quality(95).auto('format').url();

	return (
		<Layout
			backgroundColor={props.project?.color || '#FFFFFF'}
			foregroundColor={props.project?.colorForeground || '#000000'}
			isPreview={props.preview}
			siteMeta={props.siteMeta}
			showFooter={false}
		>
			<Head
				description={props.project.description}
				image={projectImage}
				title={`${props.project.title} | BUCK`}
				twitter={props.siteMeta.site.socialTwitterHandle}
				url={`${process.env.NEXT_PUBLIC_SITE_URL}/work/${props.project.slug}`}
			/>

			<Project
				color={props.project.color}
				description={props.project.description}
				endDate={props.project.endDate}
				modules={props.project.modules}
				startDate={props.project.startDate}
				tagline={props.project.tagline}
				title={props.project.title}
			/>

			{props.project.related?.map((relatedProject: TypeProject | TypeResearch) => {
				return (
					<Related
						key={relatedProject.slug}
						imageAsset={relatedProject.imageCover}
						slug={relatedProject.slug}
						title={relatedProject.title}
					/>
				);
			})}
		</Layout>
	);
}
