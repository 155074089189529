import { InferGetServerSidePropsType } from 'next';
import { Feed, FeedItem } from '@components/feed';
import { Grid8 } from '@components/Grid';
import { GridItem } from '@chakra-ui/react';
import { urlFor } from '@lib/sanity';
import { PageProjectIndexProps } from '../[slug].js';
import * as React from 'react';
import Head from '@components/Head';
import Layout from '@layout';

/**
 * @author Matt Kenefick <matt.kenefick@buck.co>
 * @package Pages/Work
 * @project BUCK.co
 */
export default function WorkCategoryIndex(props: PageProjectIndexProps) {
	if (!props.feed) {
		return null;
	}

	return (
		<Layout
			backgroundColor="#272727"
			foregroundColor="#FFFFFF"
			isPreview={props.preview}
			siteMeta={props.siteMeta}
			showFooter={true}
		>
			<Head
				description="@todo description"
				image="@todo image"
				title="@todo title"
				twitter="@todo twitter"
				url="@todo url"
			/>

			<Grid8 alignItems={'end'}>
				<GridItem gridColumn={{ base: '1/-1', sm: '1/span 5' }}>
					<nav style={{ display: 'flex', gap: '1rem' }}>
						<a href="/work/brands">Brands</a>
						<a href="/work/systems">Systems</a>
						<a href="/work/experiences">Experiences</a>
						<a href="/work/campaigns">Campaigns</a>
						<a href="/work/content">Content</a>
						<a href="/work">All the things</a>
					</nav>
				</GridItem>
			</Grid8>

			{props.feed?.items?.length > 0 && (
				<Grid8 isLarge>
					{/* <Splash slogan={props.feed.description} /> */}
					<Feed
					items={[...props.feed.items] as FeedItem[]}
					preventContentOverlapWithLogoAndMenu={true}
					/>
				</Grid8>
			)}
		</Layout>
	);
}
