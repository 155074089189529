import { Asset } from '@lib/types';
import { Box, GridItem, Text, AspectRatio } from '@chakra-ui/react';
import { Grid8 } from './Grid';
import { Link } from '@chakra-ui/react';
import * as React from 'react';
import StyledSanityImage from './SanityImage';

export interface RelatedProps {
	imageAsset?: Asset;
	slug: string;
	title: string;
}

export const Related: React.FC<RelatedProps> = (props) => {
	if (!props.slug) return null;

	return (
		<Box as="section" className="related" bg="white" color="black">
			<Grid8 isLarge flush="vertical" rowSpacing="flush" mb="0">
				<GridItem gridColumn={{ base: '3/-1', md: '5 / -1' }} fontSize={{ base: 'md', sm: 'lg', lg: 'xl' }} my="24">
					<Text mb="0" fontWeight="bold">
						Next Project
					</Text>
					<Link opacity={0.5} _hover={{ opacity: 1 }} href={props.slug}>
						{props.title} →
					</Link>
				</GridItem>

				{props.imageAsset && (
					<GridItem gridColumn={{ base: '1/-1' }} mx={{ base: -4, sm: 0 }}>
						<Link href={props.slug}>
							<AspectRatio ratio={{ base: 1 / 1, sm: 3 / 1 }}>
								<StyledSanityImage asset={props.imageAsset} transition="all .2s ease-in-out" _hover={{ opacity: 0.5 }} />
							</AspectRatio>
						</Link>
					</GridItem>
				)}
			</Grid8>
		</Box>
	);
};

export default Related;
