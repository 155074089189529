import * as React from 'react';

interface InfoDateRangeProps {
	endDate?: string;
	startDate?: string;
}

export const InfoDateRange: React.FC<InfoDateRangeProps> = (props) => {
	const startYear = props.startDate && new Date(props.startDate).getFullYear();
	const endYear = props.endDate && new Date(props.endDate).getFullYear();

	if (startYear == endYear) {
		return <>{startYear}</>;
	}
	else if (startYear && endYear) {
		return (
			<>
				{startYear} — {endYear}
			</>
		);
	}
	else if (startYear) {
		return <>{startYear}</>;
	}
	else if (endYear) {
		return <>{endYear}</>;
	}

	return null;
};

export default InfoDateRange;
