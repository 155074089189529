import { getFeedPageBySlug, getProjectPageBySlug } from '@lib/api';
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import { Feed, FeedPage, Project, ProjectPage, SiteMetaData } from '@lib/types.js';
import * as React from 'react';
import PageWorkCategoryIndex from './category';
import PageWorkProjectIndex from './project';

/**
 * @author Matt Kenefick <matt.kenefick@buck.co>
 * @package Pages/Work
 * @project BUCK.co
 */
export default function PageProjectIndex(props: InferGetServerSidePropsType<typeof getServerSideProps>) {
	if (props.project) {
		return <PageWorkProjectIndex {...(props as PageProjectIndexProps)} />;
	}
	else if (props.feed) {
		return <PageWorkCategoryIndex {...(props as PageProjectIndexProps)} />;
	}
}

/**
 * @type interface
 */
export interface PageProjectIndexProps {
	feed?: Feed;
	preview: boolean;
	project?: Project;
	siteMeta: SiteMetaData;
}

export const getServerSideProps = async (props: GetServerSidePropsContext<{ slug: string }>) => {
	const isSpecial: boolean = isSpecialPage(props.params?.slug || '');
	const page = isSpecial
		? await getFeedPageBySlug(props.params?.slug, props.preview)
		: await getProjectPageBySlug(props.params?.slug, props.preview);
	const pageProps: PageProjectIndexProps = {
		preview: !!props.preview,
		siteMeta: page?.siteMeta,
	};

	if (isSpecial) {
		pageProps.feed = (page as FeedPage)?.feed;
	}
	else {
		pageProps.project = (page as ProjectPage)?.project;
	}

	return {
		notFound: isSpecial ? !(page as FeedPage)?.feed : !(page as ProjectPage)?.project,
		props: pageProps,
	};
};

/**
 * @param string slug
 * @return boolean
 */
function isSpecialPage(slug: string): boolean {
	const specialPages = ['brands', 'campaigns', 'content', 'experiences', 'systems'];

	return specialPages.includes(slug);
}
